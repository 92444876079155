import getCustomerConfig from 'components/bricks/data/customerConfig';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { Brick, BrickSubType, BrickType } from 'components/bricks/types/brick.type';
import { UnifiedTab } from 'components/bricks/types/bricksComponentStore.type';
import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { DynamicValueOption } from 'components/input/DynamicData/types/dynamic-value.type';
import ComponentStore from 'components/data/ComponentStore';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import cloneDeep from 'helpers/cloneDeep';

class MultiInputWrapperHelpers {
    /*
     * Deletes inputs from the interface setup based on the customer config. A customer can disable inputs if they want through the config
     * @param editorBlocks The interface setup that needs to be updated
     * @param brick current brick
     * @param tab current tab
     * @returns the updated editor blocks with the removed inputs from the interface setup
     */
    static removeInputs = (editorBlocks: IEditorBlock[], brick: Brick, tab: UnifiedTab) => {
        return editorBlocks.map((editorBlock) => {
            if (!editorBlock.items) return editorBlock;

            // Filter out inputs that should be removed from the interface
            editorBlock.items = editorBlock.items.filter((multiInput) => {
                if (!multiInput.identifier) return true;
                const identifiersToRemove = MultiInputWrapperHelpers.getIdentifiersToRemoveFromInterfaceSetup(brick, tab);
                return !identifiersToRemove.includes(multiInput.identifier);
            });

            return editorBlock;
        });
    };

    /**
     * Adds inputs from the interface setup based on the customer config. A customer can add inputs if they want through the config
     * @param editorBlocks The interface setup that needs to be updated
     * @param brick current brick
     * @param tab current tab
     * @returns the updated editor blocks with the added inputs from the customer config
     */
    static addInputs = (editorBlocks: IEditorBlock[], brick: Brick, tab?: UnifiedTab) => {
        const config = getCustomerConfig();

        config?.interfaceSetup?.forEach((item) => {
            if (!MultiInputWrapperHelpers.validateInterfaceUpdate(item, brick.subType, tab)) return;

            if (item.addToInterfaceSetup) {
                item.addToInterfaceSetup.forEach((editorBlock) => {
                    editorBlocks.push(editorBlock);
                });
            }
        });

        return editorBlocks;
    };

    /**
     * Modify the editorblocks by adding the block model to the items model and prefixes it with 'data'
     * @param editorBlocks
     * @returns editor blocks
     */
    static modifyModels = (editorBlocks: IEditorBlock[]) => {
        return editorBlocks.map((editorBlock: IEditorBlock) => {
            const editorBlockCopy = cloneDeep(editorBlock);

            // Creates new items for the editor block with changed model
            const newEditorBlockItems = editorBlockCopy.items?.map((item) => {
                if (item.fixedModel) return item; // Keep fixed models unchanged

                // Construct new model. Check for a blockmodel and prefix it with 'data'
                const blockModel = editorBlockCopy.blockModel ? `${editorBlockCopy.blockModel}.` : '';
                const newModel = `data.${blockModel}${item.model || ''}`;

                return { ...item, model: newModel };
            });

            editorBlockCopy.items = newEditorBlockItems;

            delete editorBlockCopy.blockModel;

            return editorBlockCopy;
        });
    };

    /**
     * Get the config and check if there are any identifiers that should be removed from the interface setup
     * @param brick The brick
     * @returns An array of identifiers that should be removed from the interface setup
     */
    static getIdentifiersToRemoveFromInterfaceSetup = (brick: Brick, tab: UnifiedTab) => {
        const config = getCustomerConfig();
        const identifiersToRemove: string[] = [];

        config?.interfaceSetup?.forEach((item) => {
            if (!MultiInputWrapperHelpers.validateInterfaceUpdate(item, brick.subType, tab)) return;

            if (item.removeFromInterfaceSetup) {
                item.removeFromInterfaceSetup.map((identifier) => {
                    identifiersToRemove.push(identifier);
                });
            }
        });

        return identifiersToRemove;
    };

    /**
     * This function checks if the update is applicable to this brick and tab
     * @param item
     * @param brick
     * @param tab
     * @returns
     */
    static validateInterfaceUpdate = (item: any, subType: BrickSubType, tab?: UnifiedTab) => {
        // Don't continue if the brick type (or 'all') is not included in the config
        if (item.subTypes && !item.subTypes.includes(subType) && !item.subTypes.includes('all')) return false;

        // Don't continue if the tab is not correct
        if (item.tab && tab?.key !== item.tab) return false;

        return true;
    };

    /**
     * This function is responsible for making sure the active formats are up to date in the bricks component store.
     */
    static handleOnActiveFormatChange = (brick: Brick, activeFormats: string[]) => {
        const bricksToAdd: Array<[string, Brick]> = [];
        const bricksToDelete: string[] = [];
        const formats = CreativeV2FormatHelpers.getFormats(brick?.data?.creatives?.[0]?.data?.templateIdentifier, activeFormats);

        // Iterate over the active formats to determine which bricks to add
        for (const format of formats) {
            const customBrickId = BrickHelpers.getCustomBrickId(brick.id, format.key);
            const brickModel = BrickHelpers.getBrickPrefix(customBrickId);
            const customBrick = BrickHelpers.createCustomBrick(brick, 'creative_format', format, { format: format.key });
            const brickExists = BrickHelpers.getBrickById(customBrickId);

            if (!brickExists) {
                bricksToAdd.push([brickModel, customBrick]);
            }
        }

        // Iterate over the existing bricks to determine which bricks to delete
        const existingBricks = BrickHelpers.getChildrenOfBrick(brick); // Assuming this function exists to get all bricks by parent ID
        for (const existingBrick of existingBricks) {
            const format = existingBrick.data?.format;
            if (format && !activeFormats.includes(format)) {
                bricksToDelete.push(existingBrick.id);
            }
        }

        // Add new bricks
        if (bricksToAdd.length > 0) {
            ComponentStore.setMultiModels('Bricks', bricksToAdd);
        }

        // Delete existing bricks
        if (bricksToDelete.length > 0) {
            BricksComponentStoreHelper.deleteMultipleBricks(bricksToDelete);
        }
    };

    /**
     * Adds dynamic value options to the inputs based on the brick type
     * Adds the pointer and parent pointer inputs to the master and master child bricks
     *
     * @param editorBlocks The interface setup that needs to be updated
     * @param brick The current brick
     * @param allDynamicValueOptions All dynamic value options that the user can use to built up their pointer
     * @returns The updated editor blocks with the dynamic value options added to the inputs
     */
    static setupBrickAsMasterBrick = (editorBlocks: IEditorBlock[], brick: Brick, allDynamicValueOptions: DynamicValueOption[]): IEditorBlock[] => {
        // if the brick isnt a master brick, we don't want to add dynamic value options
        if (![BrickType.MASTER, BrickType.MASTER_CHILD].includes(brick.type)) {
            return editorBlocks;
        }

        return editorBlocks.map((editorBlock, index) => {
            if (!editorBlock.items) editorBlock.items = [];
            if (editorBlock.items) {
                editorBlock.items = editorBlock.items.map((item) => {
                    item.dynamicValueActive = true;

                    item.dynamicValueOptions = [...allDynamicValueOptions];

                    return item;
                });
            }

            if (index === 0) {
                editorBlock.items.push({
                    type: 'switch',
                    identifier: `${brick.subType}-customPointer`,
                    model: 'feed.useCustomPointer',
                    itemType: 'switch',
                    label: 'Use custom pointers'
                });
                editorBlock.items.push({
                    type: 'dynamicData',
                    identifier: `${brick.subType}-pointer`,
                    model: 'feed.pointer',
                    itemType: 'input',
                    label: 'Pointer',
                    maxLength: 150,
                    useValueObject: false,
                    condition: `additionalVars.brick.feed.useCustomPointer`,
                    dynamicValueOptions: [...allDynamicValueOptions]
                });

                if (brick.type === 'master_child') {
                    editorBlock.items.push({
                        type: 'dynamicData',
                        title: 'Parent Pointer',
                        identifier: `${brick.subType}-parentPointer`,
                        model: 'feed.parentPointer',
                        itemType: 'input',
                        label: 'Parent Pointer',
                        maxLength: 150,
                        useValueObject: false,
                        condition: `additionalVars.brick.feed.useCustomPointer`,
                        dynamicValueOptions: [...allDynamicValueOptions]
                    });
                }
            }
            return editorBlock;
        });
    };
}

export default MultiInputWrapperHelpers;
