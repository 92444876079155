import React, { useEffect, useState } from 'react';
import useBrick from 'components/bricks/hooks/useBrick';
import { AdSetupFrameType } from 'components/creatives-v2/components/social-previews-v2/social-preview.type';
import BricksCreativesPreviewHeader from './components/header';
import Content from './components/content';
import { AvailablePreviewPlacement } from './types/CreativePreview.type';
import CreativePreviewHelper from './helpers/creatives-preview.helpers';

import './styles/main.scss';

export type Direction = 'next' | 'previous';

interface Props {
    activeTabKey?: string;
}

const CreativesPreview: React.FC<Props> = ({ activeTabKey }) => {
    const { brick } = useBrick();
    const adSetupType: AdSetupFrameType = brick?.data?.adSetup?.type;

    const [placements, setPlacements] = useState<AvailablePreviewPlacement[]>([]);
    const [activePlacement, setActivePlacement] = useState<string>('');

    const handleSelectChange = (newPlacement: string) => {
        setActivePlacement(newPlacement);
    };

    useEffect(() => {
        if (!brick) return;
        const availablePreviewPlacements = CreativePreviewHelper.getAvailablePreviewPlacements(brick, adSetupType);
        if (availablePreviewPlacements.length > 0) {
            // Always reset placements
            setPlacements(availablePreviewPlacements);

            // Always update active placement based on new adType
            const firstAvailable = availablePreviewPlacements[0].value;
            setActivePlacement(firstAvailable);
        }
    }, [brick?.id, brick?.data?.adSetup?.type]);

    const handleSwipe = (direction: Direction) => {
        const currentPlacementIndex = placements.findIndex((placement) => placement.value === activePlacement);
        const nextPlacementIndex = direction === 'next' ? currentPlacementIndex + 1 : currentPlacementIndex - 1;

        // take into account that the array is circular
        if (nextPlacementIndex < 0) {
            setActivePlacement(placements[placements.length - 1].value);
            return;
        }
        if (nextPlacementIndex >= placements.length) {
            setActivePlacement(placements[0].value);
            return;
        }

        const nextPlacement = placements[nextPlacementIndex];
        setActivePlacement(nextPlacement.value);
    };

    if (!brick) return null;

    return (
        <div className="bricks-creatives-preview">
            {placements.length > 1 && (
                <div className="bricks-creatives-preview__header">
                    <BricksCreativesPreviewHeader
                        onSelectChange={handleSelectChange}
                        onSwipe={handleSwipe}
                        activePlacement={activePlacement}
                        placements={placements}
                        brick={brick}
                        activeTabKey={activeTabKey}
                    />
                </div>
            )}
            <div className="bricks-creatives-preview__content">
                <Content brick={brick} activePlacement={CreativePreviewHelper.getPlacementObject(brick.subType, activePlacement)} activeTabKey={activeTabKey} />
            </div>
        </div>
    );
};

export default CreativesPreview;
